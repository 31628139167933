.news
{
  &-item
  {
    h4
    {
      font-size:16px;
    }

    &-date
    {
      color: $brown;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }
}

.article
{
  a
  {
    text-decoration: none;

    p
    {
      color: black;
    }
  }
}