$font-size-base:              0.875rem;

$h1-font-size:                $font-size-base * 1.5 !default;
$h2-font-size:                $font-size-base * 1.4 !default;
$h3-font-size:                $font-size-base * 1.2 !default;
$h4-font-size:                $font-size-base * 2.2 !default;

$headings-font-weight:        600;

$enable-shadows: false;
$btn-active-box-shadow: none;
$input-btn-focus-width: 0rem;

$gray-100: #e9ebf3;
$gray-800: #1f1a17;
$white: #ffffff;
$secondary: #b3b1be;

$brown: #6c1622;
$red: #ff0f36;

$border-radius:               0rem;
$border-radius-lg:            0rem;
$border-radius-sm:            0rem;

$border-width: 2px;
$input-btn-padding-y:         .25rem !default;
$input-btn-padding-x:         1.5rem !default;

$navbar-dark-color:                 rgba($white, 1);
$navbar-dark-hover-color:           rgba($red, 1);

$navbar-dark-toggler-border-color: tranparent;

$font-family-sans-serif:      "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

