.nav-item {
  &.ico
  {
    a:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      background-position: center;
      background-repeat: no-repeat;
      filter: brightness(50%);
      transition: $transition-base;
    }

    a.dropdown-toggle:not(.collapsed)
    {
      &:before
      {
        filter: brightness(100%);
      }

    }
    a:hover
    {
      &:before
      {
        filter: brightness(100%);
      }
    }
    &.ico_1 a:before
    {
      background-image: url("../images/ico_1.png");
    }
    &.ico_2 a:before
    {
      background-image: url("../images/ico_2.png");
    }
    &.ico_3 a:before
    {
      background-image: url("../images/ico_3.png");
    }
    &.ico_4 a:before
    {
      background-image: url("../images/ico_4.png");
    }
    &.ico_5 a:before
    {
      background-image: url("../images/ico_5.png");
    }
    &.ico_6 a:before
    {
      background-image: url("../images/ico_6.png");
    }
    &.ico_7 a:before
    {
      background-image: url("../images/ico_7.png");
    }
    &.ico_8 a:before
    {
      background-image: url("../images/ico_8.png");
    }
    &.ico_9 a:before
    {
      background-image: url("../images/ico_9.png");
    }

    ul
    {
      li
      {
        padding-left:25px;

        a.nav-link
        {
          background-image: none;

          &:hover
          {
            &:before {
              background-color: $red;
            }
          }
          &:before
          {
            filter: brightness(100%);

            display: inline-block;
            background-image: none;
            background-color: $brown;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .nav-link {
    transition: $transition-base;
  }
}

.dropdown-toggle
{

  &::after
  {
    color: $brown;
    transition: $transition-base;
  }

  &:hover::after
  {
    color: $red;
  }

  &:not(.collapsed)::after
  {
    transform: rotate(180deg);
  }
}
