.menu-catalog
{
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .catalog_thumb {
    padding: 1rem;


  }
}

.thumb-warpper {
  display: flex;
  flex-direction: column;
  color: $black;
  text-decoration: none;
  position: relative;
  height: 100%;

  &:hover
  {
    &:before
    {
      border: 2px solid $red;
    }

    .btn {

      &.btn-outline-secondary
      {

        border: 2px solid $red;
        color: $white;
        background-color: $red;

        &:before {
          border-right: 2px solid $red;
        }
        &:after {
          border-left: 2px solid $red;
        }

      }

    }

  }

  .bg
  {
    background-color: $white;
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
  }

  .content
  {
    z-index: 30;
  }
  &:before
  {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 6px;
    top: 6px;
    border: 2px solid $white;
    background-color: $light;
    opacity: 0.5;
    transition: $transition-base;
  }

  .thumb_img {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    z-index: 30;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .thumb_title
  {
    padding: 0rem 1rem 1rem;
    position: relative;
    z-index: 30;

    &:after
    {
      content:'';
      display: block;
      height: 2px;
      width: 48px;
      position: absolute;
      left: 1rem;
      bottom: 0px;
      background-color: $brown;
    }
  }

  .thumb_description
  {
    z-index: 30;

    padding: 0rem 1rem;
    color: $gray-600;
  }

  .thumb_button
  {
    z-index: 30;

    padding: 0rem 1rem 1rem;
    color: $gray-600;
  }
}