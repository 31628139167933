footer
{
  .phone
  {
    a {
      text-decoration: none;
      font-style: normal;
      color: $dark;
    }
  }

  .mail
  {
    a {
      color: $dark;
      font-style: italic;
      font-weight: 500;
      transition: $transition-base;

      &:hover
      {
        color: $red;
      }
    }
  }
}