.top-bar
{
  padding: 1rem 1rem;

  .phone
  {
    a {
      text-decoration: none;
      font-style: normal;
      color: $dark;
    }
  }

  .mail
  {
    a {
      color: $dark;
      font-style: italic;
      font-weight: 500;
      transition: $transition-base;

      &:hover
      {
        color: $red;
      }
    }
  }

  @include media-breakpoint-down(sm)
  {
    margin-top:64px;
  }

}