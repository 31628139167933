.news
{
  &-item.video

    {
      padding: 1rem;
      position: relative;
      counter-increment: item-blog;

      img
      {
        width: 100%;
        margin-bottom: 1rem;
      }

      h4
      {
        margin-top: -2.2rem;
        background-color: $light;
        display: block;
        float: left;
        position: relative;

      }

      h6
      {
        margin-bottom: 1rem;
      }


      p
      {
        position: relative;
      }

      a
      {
        color: $black;
        text-decoration: none;
      }
    }

}