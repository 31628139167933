body {

}

.wrapper
{
  min-height: 100vh;

  & > .row
  {
    min-height: 100vh;
  }
}

.social a
{
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid $brown;
  color: $light;
  background-color: $brown;
  text-align: center;
  transition: $transition-base;

  &:nth-child(even)
  {
    color: $brown;
    background-color: $light;
  }

  &:hover
  {
    background-color: $red;
    color: $light;
    border: 2px solid $red;

  }
}
.btn {
  position: relative;
  text-transform: uppercase;
  font-weight:700;

  &.btn-outline-secondary
  {
    &:before {
      content: "";
      position: absolute;
      top: -8px;
      left: -8px;
      width: 16px;
      height: 16px;
      border: none;
      transform: rotate(45deg);
      border-right: 2px solid $secondary;
      background-color: $white;
      transition: $transition-base;
    }
    &:after {
      content: "";
      position: absolute;
      right: -8px;
      bottom: -8px;
      width: 16px;
      height: 16px;
      border: none;
      transform: rotate(45deg);
      border-left: 2px solid $secondary;
      background-color: $white;
      transition: $transition-base;
    }

    &:hover
    {
      border: 2px solid $red;
      color: $white;
      background-color: $red;

      &:before {
        border-right: 2px solid $red;
      }
      &:after {
        border-left: 2px solid $red;
      }
    }
    &:not(.disabled):active, &:not(.disabled):focus
    {
      border: 2px solid rgba($red,0);
      color: $white;
      background-color: rgba($red,0.5);

      &:before {
        border-right: 2px solid rgba($red,0.5);
        background-color: $white;

      }
      &:after {
        border-left: 2px solid rgba($red,0.5);
        background-color: $white;
      }
    }
  }

}

a
{
  color: $brown;

  &:hover
  {
    color: $brown;
  }
}

img
{
  max-width: 100%;
}

.lead
{
  color: $brown;
  font-size: inherit;
  font-weight: bold;
}

.nav-link
{
  line-height: 14px;
}

.item-block
{
  padding: 1rem;
  position: relative;
  counter-increment: item-blog;
  margin-bottom: 3rem;

  img
  {
    width: 100%;
  }

  h4
  {
    margin-top: -2.2rem;
    background-color: $light;
    display: block;
    float: left;
    position: relative;

    & + .separator
    {
      clear: both;
    }
    &:before
    {
      content: counter(item-blog);
      position: absolute;
      width: 28px;
      height: 25px;
      background-image: url(../images/ico_13.png);
      background-size: contain;
      left: -30px;
      top: -12px;
      z-index: 9;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    }
  }

  h6
  {
    margin-bottom: 1rem;
  }

  &:before
  {
    border: 2px solid $white;
    content:'';
    position: absolute;
    height: 100%;
    width: 75%;
    left: 0px;
    top: 0px;

    @include media-breakpoint-down(sm)
    {
      width: 100%;
    }
  }
  &.right
  {
    .row
    {
      flex-direction: row-reverse;
    }

    &:before
    {
      right: 0px;
      left: auto;
    }
  }


  p
  {
    position: relative;
  }
}

.content-body
{
  background-color: $white;
  padding: 1rem;
  margin: 3rem -15px 2rem;

  @include media-breakpoint-down(sm)
  {
    margin-top:1rem;
  }

  h1
  {
    font-size: 2rem;
    margin-top: -2.35rem;
    margin-bottom: 2rem;

    img
    {
      position: absolute;
    }
  }
}


.play {
  background: $gray-800;
  border-radius: 50% / 10%;
  color: #FFFFFF;
  font-size: 2em; /* change this to change size */
  height: 3em;
  padding: 0;
  text-align: center;
  text-indent: 0.1em;
  transition: all 150ms ease-out;
  width: 4em;
  position: absolute;
  left: 50%;
  margin-left: -2em;
  top: 50%;
  margin-top: -1.5em;
}

.play:hover {
  background: $red;
}

.play::before {
  background: inherit;
  border-radius: 5% / 50%;
  bottom: 9%;
  content: "";
  left: -5%;
  position: absolute;
  right: -5%;
  top: 9%;
}

.play::after {
  border-style: solid;
  border-width: 1em 0 1em 1.732em;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.5);
  content: ' ';
  font-size: 0.75em;
  height: 0;
  margin: -1em 0 0 -0.75em;
  top: 50%;
  position: absolute;
  width: 0;
}

ul.yiiPager
{
  .first
  {
    display: none;
  }
  .previous
  {
    display: none;
  }
  .next
  {
    display: none;
  }
  .last
  {
    display: none;
  }
  .page
  {
    background-color: $light;
    color: $black;
    font-size:14px;


    a
    {
      border: 2px solid $white;
      color: $black !important;

    }
    a:link
    {
      background-color: $light;
      color: $black !important;
      border: 2px solid $white !important;
      display: inline-block;
      height: 1.75rem;
      width: 1.75rem;
      text-align: center;
      line-height: 1.5rem;

    }

    &.selected
    {
      background-color: $light;

      a
      {
        background: none;
        color: $brown !important;
        background-color: $light !important;
        border: 2px solid $white;

      }
    }
  }
}

table
{
  border: 2px solid $brown;

  thead
  {
    td
    {
      background-color: $brown;
      color: white;
      padding: 1rem;
      font-weight: bold;
    }
  }

  tbody
  {
    tr
    {
      td
      {
        padding: 1rem;
      }

      &:nth-child(odd)
      {
        td
        {
          background-color: $white;
        }
      }
    }


  }

  margin-bottom: 2rem;
}

li {
  list-style-type: none; /* Прячем исходные маркеры */

  &.nav-item:before
  {
    display: none;
  }
}
li:before {
  color: $brown; /* Цвет маркера */
  content: "•"; /* Сам маркер */
  margin-left: -16px;
  position: absolute;
}

td
{
  width: auto !important;
}


.content-body
{
  h3, p, ul, li, .tabs-container, a
  {
    background-color: transparent !important;
  }
}

iframe
{
  max-width: 100%;
}